@import '../node_modules/antd/dist/antd.less';
@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';


body {
    background: #364150;
}

.map-page-container {
    position: relative;
    height: 800px;
}

.map-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #ffffff;
    padding: 6px 12px;
    font: 15px/24px monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}

.marker-delivery {
    background-image: url('gps.png');
    background-size: cover;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.marker-pickup {
    background-image: url('merchant-icon.png');
    background-size: cover;
    color: green;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.marker-driver {
    background-image: url('driver-icon.png');
    background-size: cover;
    color: green;
    width: 15px;
    height: 15px;
    cursor: pointer;
}

.mapboxgl-popup {
    max-width: 500px;
}

.text-center-popup{
    text-align: center;
    margin-top: 10px;
}

.button-popup{
    color: #fff;
    background: #2FAC66;
    border-color: #2FAC66;
    margin: 5px;
    padding: 5px;
}
  
.mapboxgl-popup-content {
    text-align: left;
    font-family: 'Open Sans', sans-serif;
}

.logo-login {
    height: 60px;
    margin: 40px auto;
    text-align: center;
}

.login {
    background: #364150;
}

.form-login {
    background:#ECEEF1;
    padding: 20px;
    margin: 40px auto;
    width: 350px;
    text-align: center;
}

.logoImgLogin{
    height: 80px;
    margin: auto;
}

.h2-login {
    color: #4FB879;
    font-weight: 400;
    font-size: 30px;
}

.download-exemple {
    margin: 10px 0;
}
  
.site-layout .site-layout-background {
    background: #fff;
}
  
.logo {
    height: 36px;
    margin: 16px;
}
    
.logoImg  {
    height:37px;
    text-align: center;
    margin-left: 13px;
}

.avatar {
    text-align: right;
    margin-right: 20px;
    position: absolute;
    top: 0;
    right: 0;
}

.logoutButton {
    margin: 5px 5px;
    padding: 5px auto;
    border: black 1px;
    background-color: #CCCCCC;
}

.visual{
    width: 80px;
    height: 80px;
    display: block;
    float: left;
    padding-top: 10px;
    padding-left: 15px;
    margin-bottom: 15px;
    font-size: 35px;
    line-height: 35px;
}

.visual-icon {
    margin-left: -35px;
    font-size: 100px;
    line-height: 100px;
    opacity: .1;
    color: #2FAC66;
}

.details{
    position: absolute;
    right: 15px;
}

.button-add {
    position: absolute;
    right: 40px;
    top : 160px;
}

.button-course {
    width: 120px;
}

.ant-drawer-title {
    color: #2FAC66;
    font-size: 20px;
}

.custom-tooltip {
    border: solid 1px rgb(150, 148, 148);
    border-radius: 5px;
    padding: 5px 5px;
    background-color: #fff;
    opacity: 0.5;
}

.custom-tooltip-title {
    font-weight: bold;
}

.custom-tooltip-label, p {
    margin: 0;
}

.ant-page-header-heading-title {
    font-weight: normal;
    color: #697881;
}

.searchCourse{
    display: inline-block;
}

.opening-schedule-title{
    display: inline-block;
}

.opening-schedule-day{
    width: 205px;
    margin-left: 40px;
    margin-bottom: 16px;
    margin-top: 16px;
}

.warning-course{
    position: absolute;
    top: 160px;
    right: 50px;
}

.map-dispatch-filter{
    margin-top: 10px;
    margin-bottom: 10px;
}

.filter-title{
    width: 160px;
    margin-bottom: 18px;
}

.filter{
    width: 300px;
    margin-bottom: 18px;
}

.filter-button{
    margin-left: 168px;
}

.site-tag-plus {
    background: #fff;
    border-style: dashed;
    padding: 5px;
    font-size: 14px;
    margin-bottom: 10px;
}

.tag {
    padding: 5px;
    font-size: 14px;
}

.input-tag {
    margin-bottom: 10px;
}

.site-page-header {
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
}

.ant-page-header-heading-title {
    color: #2FAC66;
}

.ant-page-header-heading-left {
    margin: 0;
}

.ant-page-header-heading .ant-avatar {
    font-size: 20px;
    color: #2FAC66;
    background: transparent;
    margin-right: 6px;
}

.ant-divider-horizontal {
    margin: 16px 0;
}

div.scroll {
    padding: 4px;
    overflow: auto;
    white-space: nowrap;
}

.delivery-schedule .ant-table.ant-table-small .ant-table-title,
.delivery-schedule .ant-table.ant-table-small .ant-table-footer,
.delivery-schedule .ant-table.ant-table-small .ant-table-thead > tr > th,
.delivery-schedule .ant-table.ant-table-small .ant-table-tbody > tr > td,
.delivery-schedule .ant-table.ant-table-small tfoot > tr > th,
.delivery-schedule .ant-table.ant-table-small tfoot > tr > td {
    padding: 0px;
}

.delivery-schedule .table-header-absolute {
    position: absolute;
    left: calc(50%);
    top: 0;
    padding: 5px 10px;
}

.delivery-schedule-block-course {
    width: 100%;
    height: 76px;
    margin: 0px;
    padding: 5px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
}

.delivery-schedule-text-overflow {
    width: calc(100% - 12px);
    display: inline-block;
    overflow: clip;
    white-space: nowrap;
    text-overflow:  ellipsis;
}

.delivery-schedule-hourly {
    width: 100%;
    height: 86px;
    margin: 0;
    padding: 30px;
    text-align: center;
    border-radius: 0;
}

// for full block
.delivery-schedule-hourly-course {
    padding: 10px;
    border: none;
    cursor: pointer;
}

.delivery-schedule-hourly-header {
    textAlign: left;
    padding: 8px 15px;
}

.delivery-schedule-hourly-now {
    border-left: 2px solid red;
}

.delivery-schedule-hourly-close {
    padding: 30px;
    background-color: #f0f0f0;
    color: white;
}

.delivery-schedule-hourly-warning {
    border: 3px solid rgb(244, 113, 116);
}

.delivery-schedule-hour-line {
    position: absolute;
    background: red;
    width: 2px;
    height: calc(100% - 33px);
    top: 33px;
}

.ant-page-header-heading-extra {
    margin: 0;
}
@primary-color: #2FAC66;@link-color: #2FAC66;